import React, {useEffect, useState} from 'react';
import {Button, Stack, Typography} from "@mui/material";
import {Form, Formik} from 'formik';
import {Unit, UnitData, usePostUnitMutation,} from '../../../redux/vmsApi'
import LoadingCover from '../../common/LoadingCover'
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {enGB} from 'date-fns/locale'
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";


import createUnitModel, {UnitType} from '../../forms/models/createUnitModel';
import CreateUnitForm from '../forms/CreateUnitForm';

type MyFormValues = UnitType;

const createUnitModelInitial = createUnitModel.initialValues

type Props = {
  onSuccess?: (Unit: Unit) => void;
};

const CreateUnitComponent: React.FC<Props> = ({onSuccess}) => {
  const [createUnit, {isSuccess, isLoading}] = usePostUnitMutation();
  const [showOverlay, setShowOverlay] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  useEffect(() => {
    setShowOverlay(isLoading || isSuccess);
    if (isSuccess) {
      setTimeout(() => {
        setShowOverlay(false)
        setShowSuccessMsg(true)
      }, 3000);
    }
  }, [isLoading, isSuccess]);

  const initialValues: MyFormValues = {
    ...createUnitModelInitial,
  };

  const handleSubmit = async (values: MyFormValues) => {


    const unitData: UnitData = {
      unitName: values.unitName,
      projectId: values.project,
    };

    setShowOverlay(true)
    try {
      const result = await createUnit({unitData}).unwrap();
      setShowOverlay(false);
      setShowOverlay(false);
      if (result) {
        console.log('success')
      }
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {
      // Handle error state
      setShowOverlay(false);
      console.error("Error submitting form:", error);
    }
  }

  if (showSuccessMsg) {
    return (
      <Typography>Thank you, your response has been received</Typography>
    )
  }

  return (
    <React.Fragment>
      {showOverlay && <LoadingCover isSuccess={isSuccess}/>}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        // validationSchema={}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
          <Form>
            <Stack spacing={4} width="100%" p={10} pb={5}>
              <CreateUnitForm/>
              <Button
                variant="contained"
                type="submit"
              >
                Create
              </Button>
            </Stack>
          </Form>
        </LocalizationProvider>
      </Formik>
    </React.Fragment>
  )
}

export default CreateUnitComponent;
