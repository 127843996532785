import React, { useState } from 'react';

import { Box, Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import EditSupervisorDialog from './EditSupervisorDialog';
import { Supervisor, SupervisorPartial, useGetSupervisorsQuery, usePatchSupervisorsByIdMutation } from '../../redux/vmsApi';


const SupervisorsList: React.FC = () => {
  const { data: supervisors, isLoading, isError } = useGetSupervisorsQuery({});
  const [patchSupervisor] = usePatchSupervisorsByIdMutation();
  const [selectedSupervisor, setSelectedSupervisor] = useState<Supervisor | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleEditClick = (supervisor: Supervisor) => {
    setSelectedSupervisor(supervisor);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedSupervisor(null);
  };

  const handleSave = async (supervisor: SupervisorPartial) => {
    if (supervisor.id) {
      try {
        await patchSupervisor({ id: supervisor?.id, supervisorPartial: supervisor });
        // Handle successful save
        setIsDialogOpen(false); // Close the dialog
      } catch (error) {
        console.error("Error updating Supervisor:", error);
        // Handle error
      }
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>Error fetching Supervisors.</div>;
  }

  return (
    <Box p={10}>
      <Typography variant='h4' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
        View Supervisors
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Job Title</TableCell>
              <TableCell>Department </TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {supervisors?.map((supervisor: any) => (
              <TableRow key={supervisor.id}>
                <TableCell>{supervisor.user.name}</TableCell>
                <TableCell>{supervisor.user.jobTitle || ''}</TableCell>
                <TableCell>{supervisor.user.department || ''}</TableCell>
                <TableCell>
                  <Button onClick={() => handleEditClick(supervisor)}>Edit</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedSupervisor && (
        <EditSupervisorDialog
          open={isDialogOpen}
          onClose={handleDialogClose}
          supervisor={selectedSupervisor}
          onSave={handleSave}
        />
      )}
    </Box>
  );
}

export default SupervisorsList;
