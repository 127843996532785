import React, {useState} from 'react';
import {
  Requisition,
  RequisitionItem,
  useGetRequisitionsNewQuery
} from "../../../redux/vmsApi";
import {
  Button,
  FormControl,
  InputLabel,
  Link,
  MenuItem, Select, SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import {getDateFromObjectId} from "../../../utils/dateUtil";
import {useAppDispatch} from "../../../redux/hooks";
import {push} from "connected-react-router/immutable";

const RequisitionList: React.FC = () => {
  const dispatch = useAppDispatch();
  const [siteName, setSiteName] = useState('all');

  const { data: requisitions, isLoading, isError } = useGetRequisitionsNewQuery({ site: siteName });

  const handleChange = (event: SelectChangeEvent) => {
    setSiteName(event.target.value);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error fetching requisitions.</div>;
  }


  return (
    <React.Fragment>
      <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
        <InputLabel id="site-select-label">Site</InputLabel>
        <Select
          labelId="site-select-label"
          id="site-select"
          value={siteName}
          onChange={handleChange}
          label="Site"
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="pokugara">Pokugara</MenuItem>
          <MenuItem value="millennium">Millennium Heights</MenuItem>
          <MenuItem value="pomona">Pomona City</MenuItem>
          <MenuItem value="hills">The Hills</MenuItem>
          <MenuItem value="hq">HQ</MenuItem>
        </Select>
      </FormControl>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell align="right">Date/Time</TableCell>
              <TableCell align="right">Site</TableCell>
              <TableCell align="right">Unit(s)</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requisitions?.map((requisition: Requisition) => (
              <TableRow key={requisition.id}>
                <TableCell component="th" scope="row">
                  {requisition.title}
                </TableCell>
                <TableCell align="right">
                  {getDateFromObjectId(requisition.id ?? '')}
                </TableCell>
                <TableCell align="right">{requisition.development}</TableCell>
                <TableCell align="right">
                  {requisition.items.map((item: RequisitionItem) => (
                    `${item.unit} `
                  ))}
                </TableCell>
                <TableCell align="right"><Button onClick={() => dispatch(push(`/procurement/requisition/${requisition.id}`))}>View</Button></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Link sx={{marginTop: '50px'}}>View historic requisitions</Link>
    </React.Fragment>
  );
}

export default RequisitionList;
