import React, { ReactNode } from 'react'; 
import { Drawer, List, ListItem, ListItemText, Toolbar, CssBaseline, AppBar, Box, IconButton, Avatar, ListItemIcon, Collapse, Divider, Typography } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DashboardIcon from '@mui/icons-material/Dashboard';
import RequisitionIcon from '@mui/icons-material/Assignment';
import OrdersIcon from '@mui/icons-material/ShoppingCart';
import StakeholdersIcon from '@mui/icons-material/People';
import DevelopmentIcon from '@mui/icons-material/Build';
import UnitsIcon from '@mui/icons-material/Apartment';
import BomIcon from '@mui/icons-material/ListAlt';
import ReportsIcon from '@mui/icons-material/BarChart';
import InventoryIcon from '@mui/icons-material/Inventory';
import ItemsIcon from '@mui/icons-material/Category'; 
import LocationIcon from '@mui/icons-material/Place';
import ItemLocationIcon from '@mui/icons-material/LocationCity'; 
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../redux/hooks';
import { styled } from '@mui/material/styles';

const drawerWidth = 240;

const Root = styled('div')({
  display: 'flex',
  height: '100vh', 
});

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: 'transparent',
  boxShadow: 'none',
}));

const ToolbarStyled = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

const ToolbarRight = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const DrawerStyled = styled(Drawer)({
  width: drawerWidth,
  flexShrink: 0,
});

const DrawerPaper = styled('div')({
  width: drawerWidth,
  background: 'linear-gradient(180deg, #1C4697, #12315A)', 
  height: '100%', 
});

const DrawerContainer = styled('div')({
  overflow: 'auto',
  marginTop: '20px',
});

const Content = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

const ListItemTextStyled = styled(ListItemText)({
  cursor: 'pointer',
});

const LinkStyled = styled(Link)({
  textDecoration: 'none',
  color: 'inherit',
});

const ActiveItemStyle = {
  backgroundColor: '#3A6EA5', 
  fontWeight: 'bold',
  borderLeft: '5px solid #FFD700', 
  paddingLeft: '10px',
};

interface ProcurementContainerProps {
  children: ReactNode;
}

const ProcurementContainer: React.FC<ProcurementContainerProps> = ({ children }) => {
  const user = useAppSelector(state => state.auth.user);
  const location = useLocation();
  const [inventoryOpen, setInventoryOpen] = React.useState(false);

  const menuItems = [
    { text: 'Dashboard', path: '/procurement/', icon: <DashboardIcon /> },
    { text: 'Requisitions', path: '#', icon: <RequisitionIcon /> },
    { text: 'Purchase Orders', path: '#', icon: <OrdersIcon /> },
    { text: 'Stakeholders', path: '/procurement/stakeholders', icon: <StakeholdersIcon /> },
    { text: 'Developments', path: '/procurement/developments', icon: <DevelopmentIcon /> },
    { text: 'Units', path: '/procurement/units', icon: <UnitsIcon /> },
    { text: 'Bill of Materials', path: '/procurement/bom', icon: <BomIcon /> },
    { text: 'Reports', path: '#', icon: <ReportsIcon /> },
  ];

  const inventoryItems = [
    { text: 'Items', path: '/inventory/items', icon: <ItemsIcon /> },
    { text: 'Location', path: '/inventory/location', icon: <LocationIcon /> },
    { text: 'Item Location', path: '/inventory/item-location', icon: <ItemLocationIcon /> },
  ];

  const isActive = (path: string) => {
    return location.pathname === path || (location.pathname === '/' && path === '/procurement/procurement-process-dashboard');
  };

  const handleInventoryClick = () => {
    setInventoryOpen(!inventoryOpen);
  };

  return (
    <Root>
      <CssBaseline />
      <AppBarStyled position="fixed">
        <ToolbarStyled>
          <img
            alt="WestProp Logo"
            src="https://staging.westprop.com/wp-content/uploads/2023/08/WestProp-Logo-White.png"
            style={{ marginRight: 20, width: 150 }}
          />
          <ToolbarRight>
            <IconButton color="inherit">
              <NotificationsIcon sx={{ color: '#1C4697' }} />
            </IconButton>
            <IconButton sx={{ p: 0 }}>
              <Avatar>{user?.name?.slice(0, 1) ?? ''}</Avatar>
            </IconButton>
          </ToolbarRight>
        </ToolbarStyled>
      </AppBarStyled>
      <DrawerStyled variant="permanent">
        <DrawerPaper>
          <Toolbar />
          <DrawerContainer>
            <List>
              {menuItems.map((item) => (
                <ListItem
                  component={LinkStyled}
                  to={item.path}
                  key={item.text}
                  sx={{
                    '&:hover': {
                      backgroundColor: '#2E5E8C',
                      transition: 'background-color 0.3s ease',
                    },
                    ...(isActive(item.path) && ActiveItemStyle),
                  }}
                >
                  <ListItemIcon sx={{ color: 'white' }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={<Typography sx={{ color: 'white' }}>{item.text}</Typography>} />
                </ListItem>
              ))}

             
              <ListItem button onClick={handleInventoryClick}>
                <ListItemIcon sx={{ color: 'white' }}>
                  <InventoryIcon />
                </ListItemIcon>
                <ListItemText primary={<Typography sx={{ color: 'white' }}>Inventory</Typography>} />
                {inventoryOpen ? <ExpandLessIcon sx={{ color: 'white' }} /> : <ExpandMoreIcon sx={{ color: 'white' }} />}
              </ListItem>
              <Collapse in={inventoryOpen} timeout="auto" unmountOnExit>
                <Divider sx={{ backgroundColor: '#3A6EA5' }} /> 
                <List component="div" disablePadding>
                  {inventoryItems.map((item) => (
                    <ListItem
                      component={LinkStyled}
                      to={item.path}
                      key={item.text}
                      sx={{
                        pl: 6,
                        '&:hover': {
                          backgroundColor: '#2E5E8C',
                          transition: 'background-color 0.3s ease',
                        },
                        ...(isActive(item.path) && ActiveItemStyle),
                      }}
                    >
                      <ListItemIcon sx={{ color: 'white' }}>{item.icon}</ListItemIcon> 
                      <ListItemText primary={<Typography sx={{ color: 'white' }}>{item.text}</Typography>} />
                    </ListItem>
                  ))}
                </List>
                <Divider sx={{ backgroundColor: '#3A6EA5' }} />
              </Collapse>
            </List>
          </DrawerContainer>
        </DrawerPaper>
      </DrawerStyled>
      <Content>
        <Toolbar />
        <Box mx={'20px'}>
          {children}
        </Box>
      </Content>
    </Root>
  );
};

export default ProcurementContainer;
