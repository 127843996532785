import React, {useEffect, useState} from 'react';
import SPEARTable from "./SPEARTable";
import PageWithHeader from "../common/PageWithHeader";
import LoadingCover from "../common/LoadingCover";
import {useGetEmployeeReviewByIdQuery, useGetUserByIdQuery} from "../../redux/vmsApi";
import {Box, Button, Grid, Paper, Typography} from "@mui/material";
import {push} from "connected-react-router/immutable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useAppDispatch} from "../../redux/hooks";

interface OwnProps {
  match?: any;
}

const AdminSPEAR: React.FC<OwnProps> = (props) => {
  const {match} = props;
  const dispatch = useAppDispatch();
  const [employeeId, setEmployeeId] = useState('');
  const { data: reviewData, isError, isLoading } = useGetEmployeeReviewByIdQuery({ id: match.params.uuid });
  const { data: userData, isError: userIsError, isLoading: userIsLoading, refetch: refetchUser } = useGetUserByIdQuery({ id: employeeId });

  useEffect(() => {
    if (reviewData?.userId) {
      setEmployeeId(reviewData.userId);
      refetchUser();
    }
  }, [reviewData, refetchUser]);

  if (isLoading || userIsLoading || !reviewData || !userData) {
    return <LoadingCover />;
  }

  return (
    <PageWithHeader>
      <Box padding={5}>
        <Button color="primary" onClick={() => dispatch(push('/reviews/admin'))}>
          <ArrowBackIcon/>
          <Typography color="primary" align="left">Admin Dashboard</Typography>
        </Button>
        <Paper elevation={3} sx={{ padding: 10 }}>
          <SPEARTable reviewData={reviewData} userData={userData} admin />
        </Paper>
      </Box>
    </PageWithHeader>
  )
}

export default AdminSPEAR;