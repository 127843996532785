import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Stack,
  Button,
  Typography,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  SelectChangeEvent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  InputAdornment, Box,
} from '@mui/material';
import { Field, Formik, Form, useFormikContext } from 'formik';
import employeeReviewModel, { employeeReviewType } from '../forms/models/employeeReviewModel';
import {
  EmployeeReviewData,
  usePostAddEmployeeReviewMutation,
} from '../../redux/vmsApi';
import employeeReviewSchema from '../forms/schemas/employeeReviewSchema';
import PageWithHeader from "../common/PageWithHeader";
import { push } from "connected-react-router/immutable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAppDispatch } from "../../redux/hooks";
import { generatePastQuarters } from "../../utils/periodUtil";

type MyFormValues = employeeReviewType;

const employeeReviewModelInitial = employeeReviewModel.initialValues;

type Props = {
  onSuccess?: (invite: EmployeeReviewData) => void;
};

const {
  formField: {
    reviewPeriod,
    accomplishmentTitle,
  },
} = employeeReviewModel;

const ratingOptions = [
  { value: undefined, label: 'None', color: 'Transparent' },
  { value: 'A+', label: 'A+', color: 'Platinum' },
  { value: 'A', label: 'A', color: 'Diamond' },
  { value: 'A-', label: 'A-', color: 'Gold' },
  { value: 'B+', label: 'B+', color: 'Silver' },
  { value: 'B', label: 'B', color: 'Emerald' },
  { value: 'C', label: 'C', color: 'Jade' },
  { value: 'C-', label: 'C-', color: 'Copper' },
  { value: 'D', label: 'D', color: 'Bronze' },
  { value: 'E', label: 'E', color: 'Brass' },
  { value: 'F', label: 'F', color: 'Wood' },
];

const BSCFields: React.FC<{ sectionIndex: number }> = ({ sectionIndex }) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const [sectionTitle, setSectionTitle] = useState(values.sections[sectionIndex].title || "");
  const [sectionWeight, setSectionWeight] = useState(values.sections[sectionIndex].weight || 0);
  const textFieldVariant = 'outlined';
  const pastQuarters = generatePastQuarters();
  const [rows, setRows] = useState([{ title: '', rating: '', actual: "", target: "", weight: "", unit: undefined
  }]);

  const handleAddRow = () => {
    setRows([...rows, { title: '', rating: '', actual: "", target: "", weight: "", unit: undefined }]);
  };

  const unitOptions = [
    { value: 'percentage', label: 'Percentage' },
    { value: 'qualitative', label: 'Qualitative' },
    { value: 'compliance', label: 'Compliance' },
    { value: 'cents', label: 'Cents' },
    { value: 'date', label: 'Date' },
    { value: 'days', label: 'Days' },
    { value: 'dollar', label: 'Dollar' },
    { value: 'weeks', label: 'Weeks' },
    { value: 'hours', label: 'Hours' },
    { value: 'minutes', label: 'Minutes' },
    { value: 'number', label: 'Number' },
    { value: 'points', label: 'Points' },
  ];

  return (
    <Stack width="100%" spacing={4} sx={{ paddingY: 5 }}>
      <Stack spacing={2} direction={'row'} alignItems="center">
        <TextField
          label="Section Title"
          variant={textFieldVariant}
          value={sectionTitle}
          onChange={(e) => {
            setSectionTitle(e.target.value);
            setFieldValue(`sections[${sectionIndex}].title`, e.target.value);
          }}
          fullWidth
        />
        <TextField
          label="Weighting"
          variant={textFieldVariant}
          type="number"
          value={sectionWeight || ''}
          onChange={(e) => {
            setSectionWeight(parseFloat(e.target.value));
            setFieldValue(`sections[${sectionIndex}].weight`, parseFloat(e.target.value));
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            style: { width: '150px' }
          }}
        />
      </Stack>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Objectives</TableCell>
              <TableCell>Measures</TableCell>
              <TableCell>Weight</TableCell>
              <TableCell>Unit of Measure</TableCell>
              <TableCell>Actual</TableCell>
              <TableCell>Target</TableCell>
              <TableCell>Performance</TableCell>
              <TableCell>Score</TableCell>
              <TableCell>Weighted Score</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              const actualValue = parseFloat(row.actual) || 0;
              const targetValue = parseFloat(row.target) || 0;
              const weightValue = parseFloat(row.weight) / 100 || 0;

              const performance = targetValue > 0 ? (actualValue / targetValue) * 100 : 0;
              const score = weightValue > 0 ? (performance / 100) * weightValue : 0;

              const sectionWeightDecimal = sectionWeight / 100;
              const weightedScore = sectionWeightDecimal * score;

              return (
                <TableRow key={index}>
                  <TableCell>
                    <TextField
                      variant={textFieldVariant}
                      fullWidth
                      value={row.title || ''}
                      onChange={(e) => {
                        const updatedRows = rows.slice();
                        updatedRows[index].title = e.target.value;
                        setRows(updatedRows);
                        setFieldValue(`sections[${sectionIndex}].accomplishments`, updatedRows);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField variant={textFieldVariant} fullWidth />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant={textFieldVariant}
                      fullWidth
                      value={row.weight || ''}
                      onChange={(e) => {
                        const updatedRows = rows.slice();
                        updatedRows[index].weight = e.target.value;
                        setRows(updatedRows);
                        setFieldValue(`sections[${sectionIndex}].accomplishments`, updatedRows);
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Select
                      labelId={`unit-label-${index}`}
                      value={row.unit || ''}
                      fullWidth
                      onChange={(event) => {
                        const updatedRows = rows.slice();
                        // @ts-ignore
                        updatedRows[index].unit = event.target.value;
                        setRows(updatedRows);
                        setFieldValue(`sections[${sectionIndex}].accomplishments`, updatedRows);
                      }}
                    >
                      {unitOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <TextField variant={textFieldVariant} fullWidth value={row.actual || ''} />
                  </TableCell>
                  <TableCell>
                    <TextField variant={textFieldVariant} fullWidth value={row.target || ''} />
                  </TableCell>
                  <TableCell>
                    <TextField variant={textFieldVariant} fullWidth value={performance.toFixed(2)} InputProps={{ readOnly: true }} />
                  </TableCell>
                  <TableCell>
                    <TextField variant={textFieldVariant} fullWidth value={score.toFixed(2)} InputProps={{ readOnly: true }} />
                  </TableCell>
                  <TableCell>
                    <TextField variant={textFieldVariant} fullWidth value={weightedScore.toFixed(2)} InputProps={{ readOnly: true }} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button onClick={handleAddRow} variant="contained">Add Row</Button>
    </Stack>
  );
};

const BSC = () => {
  const dispatch = useAppDispatch();

  // @ts-ignore
  return (
    <PageWithHeader>
      <Box sx={{ padding: 10 }}>
        <Formik
          initialValues={employeeReviewModelInitial}
          validationSchema={employeeReviewSchema}
          onSubmit={(values) => {
            // handle form submission
          }}
        >
          {() => (
            <Form>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Button color="primary" onClick={() => dispatch(push("/home"))}>
                    <ArrowBackIcon fontSize="large" />Back
                  </Button>
                </Grid>
              </Grid>
              <Field name="reviewPeriod">
                {/*//@ts-ignore*/}
                {({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel>Review Period</InputLabel>
                    <Select {...field}>
                      {generatePastQuarters().map((quarter, index) => (
                        <MenuItem key={index} value={quarter}>
                          {quarter}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>
              {[0, 1, 2, 3].map((index) => (
                <BSCFields key={index} sectionIndex={index} />
              ))}
            </Form>
          )}
        </Formik>
      </Box>
    </PageWithHeader>
  );
};

export default BSC;
