import React, { useState } from 'react';
import {Development, Project, useGetDevelopmentsQuery, usePatchDevelopmentsByIdMutation} from "../../../redux/vmsApi";
import { Box, Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import EditDevelopmentDialog from '../EditDevelopmentDialog';

const DevelopmentsList: React.FC = () => {
  const { data: developments, isLoading, isError } = useGetDevelopmentsQuery();
  const [patchDevelopment] = usePatchDevelopmentsByIdMutation();
  const [selectedDevelopment, setSelectedDevelopment] = useState<Development | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleEditClick = (development: Development) => {
    setSelectedDevelopment(development);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedDevelopment(null);
  };

  const handleSave = async (development: Development) => {
    if (development.id) {
      try {
        await patchDevelopment({ id: development.id, developmentPartial: development });
        // Handle successful save
        setIsDialogOpen(false); // Close the dialog
      } catch (error) {
        console.error("Error updating development:", error);
        // Handle error
      }
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>Error fetching developments.</div>;
  }

  return (
    <Box p={10}>
      <Typography variant='h4' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
        View Developments
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Projects</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {developments?.map((development: Development) => (
              <TableRow key={development.id}>
                <TableCell>{development.name}</TableCell>
                <TableCell>{development.description || ''}</TableCell>
                <TableCell>{development.projects.map((project: Project) => project.name).join(', ')}</TableCell>
                <TableCell>
                  <Button onClick={() => handleEditClick(development)}>Edit</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedDevelopment && (
        <EditDevelopmentDialog
          open={isDialogOpen}
          onClose={handleDialogClose}
          development={selectedDevelopment}
          onSave={handleSave}
        />
      )}
    </Box>
  );
}

export default DevelopmentsList;
