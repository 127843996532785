import React from 'react';
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import PageWithHeader from "../../common/PageWithHeader";
import { Box, Button, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import LoggedOutView from "../../common/LoggedOutView";
import { push } from "connected-react-router/immutable";
import ProcurementContainer from '../containers/ProcurementContainer';
import ProcurementProcessList from '../lists/ProcurementProcessList';

const ProcurementProcess: React.FC = () => {
  const user = useAppSelector(state => state.auth.user);
  const dispatch = useAppDispatch();

  if (!user) {
    return <LoggedOutView />
  }

  if (!user.roles?.includes('procurement')) {
    return (
      <PageWithHeader>
        <Box>
          <Typography>You don't have access to this functionality</Typography>
        </Box>
      </PageWithHeader>
    )
  }

  return (
    <ProcurementContainer>
      <Grid container spacing={2} style={{ padding: '20px' }}>
        <Grid item xs={12}>
          <Typography variant='h1' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
            Procurement Tracking System
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card raised={true} sx={{ borderRadius: '20px', margin: 2, boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardContent>
              <Stack direction={'row'} justifyContent="space-between" alignItems="center">
                <Typography variant='h3' gutterBottom style={{ color: '#345678' }}>Procurement Processes Initiated</Typography>
                <Button
                  onClick={() => dispatch(push(`/procurement/create-requisition`))}
                  disabled={!user.roles?.includes('site-agent') && !user.roles?.includes('admin')}
                >
                  Initiate Procurement Process
                </Button>

              </Stack>
              <ProcurementProcessList />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </ProcurementContainer>
  )
}

export default ProcurementProcess;
