import React, { useState } from 'react';
import {
  useGetStakeholdersQuery,
  usePatchStakeholdersByIdMutation,
  Stakeholder,
} from '../../../redux/vmsApi';
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

const StakeholdersList: React.FC = () => {
  const { data: stakeholders, isLoading, isError } = useGetStakeholdersQuery({});
  const [patchStakeholder] = usePatchStakeholdersByIdMutation();
  const [selectedStakeholder, setSelectedStakeholder] = useState<Stakeholder | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleEditClick = (stakeholder: Stakeholder) => {
    setSelectedStakeholder(stakeholder);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedStakeholder(null);
  };

  const handleSave = async (stakeholder: Stakeholder) => {
    if (stakeholder.id) {
      try {
        await patchStakeholder({ id: stakeholder.id, stakeholderPartial: stakeholder });
        setIsDialogOpen(false); 
      } catch (error) {
        console.error('Error updating stakeholder:', error);
      }
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>Error fetching stakeholders.</div>;
  }

  return (
    <Box p={10}>
      <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
        View Stakeholders
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Rating</TableCell>
              <TableCell>Payment Terms</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stakeholders?.map((stakeholder: Stakeholder) => (
              <TableRow key={stakeholder.id}>
                <TableCell>{stakeholder.name}</TableCell>
                <TableCell>{stakeholder.type}</TableCell>
                <TableCell>
                  {stakeholder.contactInfo?.map((contact, index) => (
                    <div key={index}>
                      <strong>{contact.contactPerson}</strong>
                      <br />
                      Phone: {contact.phone}
                      <br />
                      Email: {contact.email}
                      <hr></hr><br/>
                    </div>
                  ))}
                </TableCell>
                <TableCell>
                  {stakeholder.address?.map((addr, index) => (
                    <div key={index}>
                      {addr.street}, {addr.city}, {addr.state}, {addr.zipCode}, {addr.country}
                      <hr></hr><br/>
                    </div>
                    
                  ))}
                </TableCell>
                <TableCell>{stakeholder.rating}</TableCell>
                <TableCell>{stakeholder.paymentTerms}</TableCell>
                <TableCell>
                  <Button onClick={() => handleEditClick(stakeholder)}>Edit</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
     
    </Box>
  );
};

export default StakeholdersList;
