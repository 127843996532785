import React from 'react';
import {Paper, Typography} from "@mui/material";

import DevelopmentsList from "../lists/DevelopmentsList";
import ProcurementContainer from '../containers/ProcurementContainer';
import CreateDevelopmentComponent from '../components/CreateDevelopmentComponent';

const DevelopmentsPage: React.FC = () => {
  return (
    <ProcurementContainer>
      <Typography variant='h1' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
        Developments
      </Typography>
      <Paper sx={{my: 5}}>
        <CreateDevelopmentComponent/>
      </Paper>
      <Paper sx={{my: 5}}>
        <DevelopmentsList/>
      </Paper>
    </ProcurementContainer>

  )
}

export default DevelopmentsPage