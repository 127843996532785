import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ScanId from "./ScanId";
import SiteAccessDashboard from './SiteAccessDashboard';
import SiteVisitApplicationPage from './SiteVisitApplicationPage';
import SiteVisitApplicationsList from './SiteVisitApplicationsList';
import SupervisorsPage from './SupervisorsPage';

const AccessControlRoutes = () => {
  return (
    <Switch>
      {/*<Route exact path="/access-control" component={} />*/}
      {/*<Route exact path="/access-control/submit-request" component={} />*/}
      <Route exact path="/access-control" component={SiteAccessDashboard} />
      <Route exact path="/access-control/apply-for-site-visit" component={SiteVisitApplicationPage} />
      <Route exact path="/access-control/site-visit-applications" component={SiteVisitApplicationsList} />
      <Route exact path="/access-control/assign-supervisor" component={SupervisorsPage} />
      <Route exact path="/access-control/scan" component={ScanId} />

    </Switch>
  );
}

export default AccessControlRoutes;
