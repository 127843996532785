import React from 'react';
import {Paper, Typography} from "@mui/material";
import ProcurementContainer from '../containers/ProcurementContainer';
import RequisitionList from '../lists/RequisitionList';



const DevelopmentsPage: React.FC = () => {
  return (
    <ProcurementContainer>
      <Typography variant='h1' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
        Requisitions
      </Typography>
      
      <Paper sx={{my: 5}}>
        <RequisitionList/>
      </Paper>
    </ProcurementContainer>

  )
}

export default DevelopmentsPage