import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Collapse,
  IconButton,
  List,
  ListItem
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  useGetUsersWithReviewsQuery, User
} from "../../redux/vmsApi";
import PageWithHeader from "../common/PageWithHeader";
import EmployeeCard from "../common/EmployeeCard";

interface OwnProps {
  match?: any;
}

const EmployeeReviewAdmin: React.FC<OwnProps> = ({ match }) => {
  const { data: usersData, isError: usersIsError, isLoading: usersIsLoading } = useGetUsersWithReviewsQuery();
  const [openDepartments, setOpenDepartments] = useState<{ [key: string]: boolean }>({});

  if (usersIsLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (usersIsError || !usersData) {
    return <Typography>Error loading user data</Typography>;
  }

  // Separate C-Suite users from the rest and group by department
  const cSuiteUsers = usersData.filter((user: User) => user.roles && user.roles.includes('c-suite'));
  const nonCSuiteUsers = usersData.filter((user: User) => user.roles && !user.roles.includes('c-suite'));

  // Group subordinates by department
  const subordinatesByDepartment: { [department: string]: User[] } = nonCSuiteUsers.reduce((acc, user: User) => {
    const department = user.department || 'Unknown';
    if (!acc[department]) {
      acc[department] = [];
    }
    acc[department].push(user);
    return acc;
  }, {} as { [department: string]: User[] });

  // Toggle the visibility of subordinates in a department
  const handleToggle = (department: string) => {
    setOpenDepartments(prev => ({
      ...prev,
      [department]: !prev[department]
    }));
  };

  return (
    <PageWithHeader>
      <Box padding={10}>
        <Paper elevation={3} sx={{ padding: 10 }}>
          {/* Display C-Suite users and subordinates by department */}
          {cSuiteUsers.map((cSuiteUser: User) => {
            const department = cSuiteUser.department || 'Unknown';
            const subordinates = subordinatesByDepartment[department] || [];

            return (
              <Box key={cSuiteUser.id} marginBottom={4}>
                <Typography variant="h6" display="flex" alignItems="center">
                  {cSuiteUser.name} - {department}
                  {subordinates.length > 0 && (
                    <IconButton onClick={() => handleToggle(department)}>
                      {openDepartments[department] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  )}
                </Typography>
                <EmployeeCard userData={cSuiteUser} />

                <Collapse in={openDepartments[department]} timeout="auto" unmountOnExit>
                  <List>
                    {subordinates.map((subordinate: User) => (
                      <ListItem key={subordinate.id}>
                        <EmployeeCard userData={subordinate} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </Box>
            );
          })}
        </Paper>
      </Box>
    </PageWithHeader>
  );
};

export default EmployeeReviewAdmin;
