import React from 'react';
import PageWithHeader from "../common/PageWithHeader";
import { Grid, Card, CardContent, Typography, Paper, Button } from '@mui/material';
import { Link, useRouteMatch } from 'react-router-dom';
import { push } from "connected-react-router/immutable";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { styled } from '@mui/material/styles';

// Styled components using MUI's `styled` utility
const StyledCard = styled(Card)(({ theme }) => ({
  height: '230px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#1976d2',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  transition: '0.3s',
  borderRadius: '15px',
  '&:hover': {
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
  },
}));

const ComingSoonCard = styled(Card)(({ theme }) => ({
  height: '230px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#b0b0b0',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: '15px',
}));

const StyledTypography = styled(Typography)({
  textAlign: 'center',
  color: '#fff',
});

const EmployeeReviewDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const { url } = useRouteMatch();

  const currentUser = useAppSelector(state => state.auth.user);

  const options = [
    { id: 1, title: 'Life Wheel', link: `${url}/life-wheel` },
    { id: 2, title: 'SPEAR', link: `${url}/spear` },
    { id: 3, title: 'BSC', link: `${url}/bsc`, comingSoon: true },
    { id: 4, title: 'PEAR', link: `${url}/pear`, comingSoon: true },
    { id: 6, title: '360 Degree Survey', link: `${url}/360-degree-survey` },
  ];

  return (
    <PageWithHeader>
      <Paper elevation={2} sx={{ margin: 10, padding: 5, borderRadius: '15px' }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography variant='h1' gutterBottom sx={{ fontWeight: 'bold', color: '#123456' }}>
              Review Dashboard
            </Typography>
          </Grid>
          {currentUser?.roles && currentUser.roles.includes('admin') &&
            <Grid item xs={6} container justifyContent="flex-end">
              <Button sx={{ display: 'inline-block' }} color="primary" onClick={() => dispatch(push('/reviews/admin'))}>
                <Typography color="primary" align="right">Admin Dashboard</Typography>
              </Button>
            </Grid>
          }
        </Grid>
        <Typography variant='body1' gutterBottom>
          Welcome to the Employee Review Dashboard. This platform allows you to submit your self-assessment reports,
          track your performance, and view feedback from your peers and supervisors. Below, you will find various tools
          and reports to help you in your self-assessment journey:
        </Typography>
        <Typography variant='body1' gutterBottom>
          <ul>
            <li><strong>Life Wheel:</strong> A monthly self-assessment tool that helps you evaluate different areas of your life.</li>
            <li><strong>SPEAR:</strong> A monthly performance evaluation framework focusing on specific, measurable goals.</li>
            <li><strong>BSC (Balance Score Card):</strong> A quarterly review tool that aligns your activities with the organization’s vision and strategy.</li>
            <li><strong>PEAR:</strong> A two-part quarterly review focusing on your professional and personal growth.</li>
            <li><strong>360 Degree Survey:</strong> A comprehensive feedback tool that gathers input from your peers, subordinates, and supervisors.</li>
          </ul>
        </Typography>
        <Grid container spacing={3} columns={30}>
          {options.map((option) => (
            <Grid item xs={15} sm={10} md={6} key={option.id}>
              {option.comingSoon ? (
                <ComingSoonCard>
                  <CardContent>
                    <StyledTypography variant="h6">
                      {option.title}
                      <Typography variant="caption" display="block" sx={{ marginTop: 1 }}>
                        COMING SOON
                      </Typography>
                    </StyledTypography>
                  </CardContent>
                </ComingSoonCard>
              ) : (
                <Link to={option.link} style={{ textDecoration: 'none', color: 'inherit', display: 'block' }}>
                  <StyledCard>
                    <CardContent>
                      <StyledTypography variant="h6">
                        {option.title}
                      </StyledTypography>
                    </CardContent>
                  </StyledCard>
                </Link>
              )}
            </Grid>
          ))}
        </Grid>
      </Paper>
    </PageWithHeader>
  );
}

export default EmployeeReviewDashboard;
