import React, { useState } from 'react';
import { Button, Stack, TextField, Typography, Checkbox, FormControlLabel, MenuItem } from '@mui/material';
import { DesktopDatePicker, TimePicker } from '@mui/x-date-pickers';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch } from '../../redux/hooks';
import { useGetUsersMeQuery, usePostAccessApplicationsMutation, useGetDevelopmentsQuery } from '../../redux/vmsApi';
import { push } from "connected-react-router/immutable";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const AddSiteVisitApplicationForm = () => {
  const dispatch = useAppDispatch();
  const [postSiteVisitApplication] = usePostAccessApplicationsMutation();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { data: user } = useGetUsersMeQuery();
  const { data: developments = [] } = useGetDevelopmentsQuery();

  const handleSubmit = async (values: any, { resetForm }: any) => {
    if (user?.id) {
      values.userId = user.id;
    }

    try {
      const response = await postSiteVisitApplication({ newAccessApplication: values }).unwrap();
      setIsSubmitted(true);
      resetForm();
    } catch (error) {
      console.error('There was an error submitting the form', error);
    }
  };

 
  const validationSchema = Yup.object().shape({
    purposeOfVisit: Yup.string().required("Purpose of Visit is required"),
    developmentId: Yup.string().required("Development is required"),
    proposedDateOfVisit: Yup.date().required("Proposed Date of Visit is required"),
    proposedTimeOfVisit: Yup.date().nullable().required("Proposed Time of Visit is required"),
    proposedTimeToLeave: Yup.date().nullable()
      .required("Proposed Time to Leave is required")
      .test('isLater', 'Proposed Time to Leave must be later than Proposed Time of Visit', function(value) {
        const { proposedTimeOfVisit } = this.parent;
        return proposedTimeOfVisit && value ? value > proposedTimeOfVisit : true;
      })
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Formik
        initialValues={{
          purposeOfVisit: '',
          userId: '',
          approved: false,
          developmentId: '',
          proposedDateOfVisit: null,
          proposedTimeOfVisit: null,
          proposedTimeToLeave: null,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <Stack spacing={4} width="100%" p={5}>
              {!isSubmitted ? (
                <>
                  <Field
                    as={TextField}
                    fullWidth
                    select
                    name="development"
                    label="Development"
                    value={values.developmentId || ''}
                    onChange={(e:any) => setFieldValue('developmentId', e.target.value)}
                    error={touched.developmentId && Boolean(errors.developmentId)}
                    helperText={touched.developmentId && errors.developmentId ? errors.developmentId : ''}
                  >
                    {developments.map((development) => (
                      <MenuItem key={development.id} value={development.id}>
                        {development.name}
                      </MenuItem>
                    ))}
                  </Field>

                  <Field
                    name="purposeOfVisit"
                    as={TextField}
                    label="Purpose Of Visit"
                    variant="outlined"
                    fullWidth
                    required
                  />

                  <DesktopDatePicker
                    label="Proposed Date of Visit"
                    inputFormat="MM/dd/yyyy"
                    value={values.proposedDateOfVisit}
                    onChange={(value) => setFieldValue('proposedDateOfVisit', value)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />

                  <TimePicker
                    label="Proposed Time of Visit"
                    value={values.proposedTimeOfVisit}
                    onChange={(value) => setFieldValue('proposedTimeOfVisit', value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={touched.proposedTimeOfVisit && Boolean(errors.proposedTimeOfVisit)}
                        helperText={touched.proposedTimeOfVisit && errors.proposedTimeOfVisit ? errors.proposedTimeOfVisit : ''}
                      />
                    )}
                  />

                  <TimePicker
                    label="Proposed Time to Leave"
                    value={values.proposedTimeToLeave}
                    onChange={(value) => setFieldValue('proposedTimeToLeave', value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={touched.proposedTimeToLeave && Boolean(errors.proposedTimeToLeave)}
                        helperText={touched.proposedTimeToLeave && errors.proposedTimeToLeave ? errors.proposedTimeToLeave : ''}
                      />
                    )}
                  />

                  <Button variant="contained" type="submit" sx={{ mt: 4 }}>
                    Submit Site Visit Application
                  </Button>
                </>
              ) : (
                <>
                  <Typography variant="h5" gutterBottom style={{ color: '#123456' }}>
                    Site Visit Application Added Successfully!
                  </Typography>
                  <FormControlLabel
                    control={<Checkbox checked={true} disabled />}
                    label="Thank you for adding a new SiteVisitApplication."
                  />
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setIsSubmitted(false)}
                  >
                    Submit Another Application
                  </Button>
                </>
              )}
            </Stack>
          </Form>
        )}
      </Formik>
    </LocalizationProvider>
  );
};

export default AddSiteVisitApplicationForm;
