import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack, Typography, Chip, TableContainer, TableBody, TableCell, Table, TableRow, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { usePutAccessApplicationsByIdMutation } from '../../redux/vmsApi';

type ApproveApplicationDialogProps = {
    open: boolean;
    onClose: () => void;
    application: any;
    onSave: (updatedApplication: any) => void;
  };
  
  const ApproveApplicationDialog: React.FC<ApproveApplicationDialogProps> = ({ open, onClose, application, onSave }) => {
    const [updateVisitApplication, { isLoading: isSubmitting }] = usePutAccessApplicationsByIdMutation();
   console.log(application);
    if (!application) return null;
  
    
    const handleApprove = async () => {
      
      const updatedApplication = {
        id: application.id,
        userId: application.userId,
        purposeOfVisit: application.purposeOfVisit,
        approved: true,
        developmentId: application.developmentId,
        proposedDateOfVisit: application.proposedDateOfVisit,
        proposedTimeOfVisit: application.proposedTimeOfVisit,
        proposedTimeToLeave: application.proposedTimeToLeave,
      };
  
      try {
       
        await updateVisitApplication({ id: application.id, updateAccessApplication: updatedApplication });
        onSave(updatedApplication); 
      } catch (error) {
        console.error('Failed to approve application:', error);
      }
    };
  
    
    const handleDisapprove = async () => {
      const updatedApplication = {
        id: application.id,
        userId: application.userId,
        purposeOfVisit: application.purposeOfVisit,
        approved: false,
        developmentId: application.developmentId,
        proposedDateOfVisit: application.proposedDateOfVisit,
        proposedTimeOfVisit: application.proposedTimeOfVisit,
        proposedTimeToLeave: application.proposedTimeToLeave,
      };
  
      try {
        await updateVisitApplication({ id: application.id, updateAccessApplication: updatedApplication });
        onSave(updatedApplication);
      } catch (error) {
        console.error('Failed to disapprove application:', error);
      }
    };
  
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>
          Approve or Disapprove Application
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableBody>
               
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" fontWeight="bold">Purpose of Visit:</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{application.purposeOfVisit}</Typography>
                  </TableCell>
                </TableRow>
  
               
                
               
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" fontWeight="bold">Development:</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{application?.development?.name}</Typography>
                  </TableCell>
                </TableRow>
  
               
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" fontWeight="bold">Proposed Date of Visit:</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{new Date(application.proposedDateOfVisit).toLocaleDateString()}</Typography>
                  </TableCell>
                </TableRow>
  
                
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" fontWeight="bold">Proposed Time of Visit:</Typography>
                  </TableCell>
                  <TableCell>
                    <Chip 
                      label={application.proposedTimeOfVisit} 
                      sx={{ bgcolor: '#e0f7fa', color: '#00796b', fontWeight: 'bold' }} 
                    />
                  </TableCell>
                </TableRow>
  
               
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" fontWeight="bold">Proposed Time to Leave:</Typography>
                  </TableCell>
                  <TableCell>
                    <Chip 
                      label={application.proposedTimeToLeave} 
                      sx={{ bgcolor: '#f3e5f5', color: '#6a1b9a', fontWeight: 'bold' }} 
                    />
                  </TableCell>
                </TableRow>
  
                
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" fontWeight="bold">Approval Status:</Typography>
                  </TableCell>
                  <TableCell>
                    {application.approved ? (
                      <Chip label="Yes" color="success" />
                    ) : (
                      <Chip label="No" color="error" />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisapprove} variant="contained" color="error" disabled={isSubmitting}>
            {isSubmitting ? 'Rejecting...' : 'Reject'}
          </Button>
          <Button onClick={handleApprove} variant="contained" color="success" disabled={isSubmitting}>
            {isSubmitting ? 'Approving...' : 'Approve'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default ApproveApplicationDialog;
