import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const RenderRequisitionHeader = ({ development }: { development: any }) => {
  return (
    <Card 
      sx={{
        background: 'linear-gradient(135deg, #1976d2 30%, #F7C914 90%)',
        color: 'white',
        padding: 3,
        borderRadius: 2,
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        position: 'relative',
        overflow: 'hidden',
        marginTop: 1,
        marginX: 2,
      }}
    >
      <CardContent sx={{ position: 'relative', zIndex: 2 }}>
        <Typography
          variant="h4"
          component="h2"
          sx={{
            fontWeight: 'bold',
            textTransform: 'uppercase',
            letterSpacing: 1.5,
            color: 'white',
          }}
        >
          {development.name}  {development.description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default RenderRequisitionHeader;
