import React, { useState } from 'react';
import PageWithHeader from "../common/PageWithHeader";
import { Button, styled, Typography, Box, Paper, CircularProgress } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { usePostGetSignedUrlMutation, usePostPayrollUploadMutation } from "../../redux/vmsApi";
import { uploadFileToS3 } from "../../utils/mediaUtil";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  maxWidth: '800px',
  width: '100%',  // Ensures responsiveness
  padding: theme.spacing(15),
  textAlign: 'center',
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[3],
  borderRadius: '25px',
}));

const UploadSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.default,
}));

const PayrollGenerator: React.FC = () => {
  const [getSignedUrl] = usePostGetSignedUrlMutation();
  const [postPayrollUpload] = usePostPayrollUploadMutation();
  const [loading, setLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<string | undefined>(undefined);
  const [message, setMessage] = useState<string | undefined>(undefined);

  const handleUploadAndGenerate = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setLoading(true);
      try {
        console.log("Starting file upload...");
        const uploadedFileUrl = await uploadFileToS3(file, getSignedUrl, () => {}, true);
        console.log("File uploaded:", uploadedFileUrl);

        console.log("Starting payroll generation...");
        const response = await postPayrollUpload({ body: { excelFile: uploadedFileUrl.url } }).unwrap();
        console.log("Payroll generated:", response);
        setPdfUrl(response.pdfUrl);
        console.log('Generated vouchers response:', response);
        setMessage(response.message);
      } catch (error) {
        console.error('Error uploading or generating vouchers:', error);
        setMessage('Error occurred. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <PageWithHeader>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="80vh"
      >
        <StyledPaper>
          <Typography variant="h2" gutterBottom>
            Payroll Generator Tool
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            Upload your Excel payroll schedule to automatically generate PDF payment vouchers.
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 4 }}>
            Supported file format: .xlsx
          </Typography>

          <UploadSection>
            <Button
              component="label"
              variant="contained"
              color="primary"
              startIcon={<CloudUploadIcon />}
              size="large"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Upload & Generate Vouchers'
              )}
              <VisuallyHiddenInput
                type="file"
                accept=".xlsx"
                onChange={handleUploadAndGenerate}
              />
            </Button>
          </UploadSection>

          {message && (
            <Typography variant="body2" color="textSecondary" sx={{ marginTop: 3 }}>
              {message}
            </Typography>
          )}
          {pdfUrl && (
            <Typography variant="body2" color="textSecondary" sx={{ marginTop: 3 }}>
              Download your vouchers <a href={pdfUrl} target="_blank" rel="noopener noreferrer">here</a>.
            </Typography>
          )}
        </StyledPaper>
      </Box>
    </PageWithHeader>
  );
};

export default PayrollGenerator;
