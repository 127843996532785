import React, { useState } from 'react';
import { Box, Typography, Paper, CircularProgress, IconButton, Chip } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useGetAccessApplicationsQuery, useGetSupervisorsQuery, useGetUsersMeQuery } from '../../redux/vmsApi';
import ApproveApplicationDialog from './ApproveSiteVisitDialog';

const SiteVisitApplications: React.FC = () => {
  const { data: user } = useGetUsersMeQuery();
  const userId = user?.id;

  const { data: supervisors } = useGetSupervisorsQuery({});
  const supervisor = supervisors?.find((supervisor: any) => supervisor.userId === userId);

  const { data: siteVisitApplications, isLoading } = useGetAccessApplicationsQuery({});

  const filteredApplications = siteVisitApplications?.filter((application: any) => {
    return supervisor?.supervisees?.some(
      (supervisee: any) => supervisee.userId === application.userId
    );
  });

  const [selectedApplication, setSelectedApplication] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = (application: any) => {
    setSelectedApplication(application);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedApplication(null);
  };

  const handleSave = (updatedApplication: any) => {
    setIsDialogOpen(false);
    setSelectedApplication(null);
  };

  const formattedApplications = filteredApplications?.map((application: any) => {
    let formattedProposedDateOfVisit = application.proposedDateOfVisit;
    let formattedProposedTimeOfVisit = application.proposedTimeOfVisit;
    let formattedProposedTimeToLeave = application.proposedTimeToLeave;

    if (application.proposedDateOfVisit) {
      const date = new Date(application.proposedDateOfVisit);
      if (!isNaN(date.getTime())) {
        formattedProposedDateOfVisit = date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'long',
          year: 'numeric',
        });
      }
    }

    if (application.proposedTimeOfVisit) {
      const timeOfVisit = new Date(application.proposedTimeOfVisit);
      if (!isNaN(timeOfVisit.getTime())) {
        formattedProposedTimeOfVisit = timeOfVisit.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        });
      }
    }

    if (application.proposedTimeToLeave) {
      const timeToLeave = new Date(application.proposedTimeToLeave);
      if (!isNaN(timeToLeave.getTime())) {
        formattedProposedTimeToLeave = timeToLeave.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        });
      }
    }

    return {
      ...application,
      userName: application.user?.name || 'N/A',
      developmentName: application.development?.name || 'N/A',
      proposedDateOfVisit: formattedProposedDateOfVisit || application.proposedDateOfVisit,
      proposedTimeOfVisit: formattedProposedTimeOfVisit || application.proposedTimeOfVisit,
      proposedTimeToLeave: formattedProposedTimeToLeave || application.proposedTimeToLeave,
      approved: application.approved ? true : false,
    };
  });

  const columns: GridColDef[] = [
    { field: 'userName', headerName: 'Applicant', width: 150 },
    { field: 'developmentName', headerName: 'Development', width: 150 },
    { field: 'proposedDateOfVisit', headerName: 'Proposed Date Of Visit', width: 200 },
    {
      field: 'proposedTimeOfVisit',
      headerName: 'Proposed Time Of Visit',
      width: 150,
      renderCell: (params) => (
        <Chip
          label={params.value}
          sx={{ bgcolor: '#e0f7fa', color: '#00796b', fontWeight: 'bold' }}
        />
      ),
    },
    {
      field: 'proposedTimeToLeave',
      headerName: 'Proposed Time To Leave',
      width: 150,
      renderCell: (params) => (
        <Chip
          label={params.value}
          sx={{ bgcolor: '#f3e5f5', color: '#6a1b9a', fontWeight: 'bold' }}
        />
      ),
    },
    {
      field: 'approved',
      headerName: 'Approved',
      width: 150,
      renderCell: (params) =>
        params.value === true ? (
          <Chip label="Yes" color="success" />
        ) : (
          <Chip label="No" color="error" />
        ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => (
        <IconButton onClick={() => handleOpenDialog(params.row)}>
          <VisibilityIcon sx={{ color: 'blue', fontSize: '24px' }} />
        </IconButton>
      ),
    },
  ];

  return (
    <Paper sx={{ my: 5, p: 5 }}>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height={400}>
          <CircularProgress />
        </Box>
      ) : (
        <DataGrid
          rows={formattedApplications || []}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
        />
      )}

      <ApproveApplicationDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        application={selectedApplication}
        onSave={handleSave}
      />
    </Paper>
  );
};

export default SiteVisitApplications;
