import React from 'react';
import {
    Autocomplete,
    Button,
    IconButton,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { Field, FieldArray, useFormikContext } from 'formik';
import { Delete as DeleteIcon } from '@mui/icons-material';
import supervisorFormModel from '../forms/models/supervisorModel';
import { useGetUsersQuery } from '../../redux/vmsApi';

const {
    formField: {
                name,
                department,
                jobTitle,
                userId,
                supervisees: { 
                    superviseeFields 
                } },
} = supervisorFormModel;

interface OwnProps {
    editing?: boolean;
}

const CreateSupervisorForm: React.FC<OwnProps> = (props) => {
    const { editing = false } = props;
    const { errors, touched, values, setFieldValue } = useFormikContext<any>();
    const { data: users } = useGetUsersQuery();

    
    const handleSupervisorAutocompleteChange = (
        event: React.ChangeEvent<{}>,
        newValue: any
    ) => {
        console.log("+++++++supervisor++++++++",newValue);
        if (newValue) {
            setFieldValue(`userId`, newValue.id);
            setFieldValue(`name`, newValue.name);
            setFieldValue(`department`, newValue.department);
            setFieldValue(`jobTitle`, newValue.jobTitle);
            console.log(values);
        } else {
            setFieldValue(`name`, '');
            setFieldValue(`department`, '');
            setFieldValue(`jobTitle`, '');
        }
    };

    
    const handleSuperviseeAutocompleteChange = (
        event: React.ChangeEvent<{}>, 
        newValue: any,
        index: number
    ) => {
        console.log("supervisee",newValue);
        if (newValue) {
            setFieldValue(`supervisees[${index}].userId`, newValue.id);
            setFieldValue(`supervisees[${index}].name`, newValue.name);
            setFieldValue(`supervisees[${index}].department`, newValue.department);
            setFieldValue(`supervisees[${index}].jobTitle`, newValue.jobTitle);
        } else {
            setFieldValue(`supervisees[${index}].name`, '');
            setFieldValue(`supervisees[${index}].department`, '');
            setFieldValue(`supervisees[${index}].jobTitle`, '');
        }
    };

    return (
        <Stack width="100%" spacing={5}>
            <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
                {editing ? 'Edit' : 'Add'} Supervisor
            </Typography>
            <Stack direction="row" spacing={2}>
            <Field  {...userId} type="hidden" />
                <Autocomplete
                    options={users || []} 
                    getOptionLabel={(option) => option.name || ''} 
                    onChange={handleSupervisorAutocompleteChange}
                    style={{ width: '600px' }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Search Supervisor"
                            variant="outlined"
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name ? errors.name : ''}
                        />
                    )}
                />

                <Field
                    as={TextField}
                    fullWidth
                    {...name}
                    error={touched[name.name] && Boolean(errors[name.name])}
                    helperText={touched[name.name] && errors[name.name] ? errors[name.name] : ''}
                />

                <Field
                    as={TextField}
                    fullWidth
                    {...department}
                    error={touched[department.name] && Boolean(errors[department.name])}
                    helperText={touched[department.name] && errors[department.name] ? errors[department.name] : ''}
                />

                <Field
                    as={TextField}
                    fullWidth
                    {...jobTitle}
                    error={touched[jobTitle.name] && Boolean(errors[jobTitle.name])}
                    helperText={touched[jobTitle.name] && errors[jobTitle.name] ? errors[jobTitle.name] : ''}
                />
            </Stack>

            <FieldArray
                name="supervisees"
                render={(arrayHelpers) => (
                    <React.Fragment>
                        {values.supervisees.map((supervisee: any, index: number) => (
                            <Stack key={index} direction="row" spacing={2} alignItems="center">
                               
                                 <Field  {...superviseeFields.userId} type="hidden" />
                              
                                <Autocomplete
                                    options={users || []}
                                    getOptionLabel={(option) => option.name || ''} 
                                    onChange={(event, newValue) =>
                                        handleSuperviseeAutocompleteChange(event, newValue, index) 
                                    }
                                    style={{ width: '600px' }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Search User"
                                            variant="outlined"
                                        
                                        />
                                    )}
                                />

                            
                                <TextField
                                    {...superviseeFields.name}
                                    value={supervisee.name}
                                    variant="outlined"
                                    fullWidth
                                    onChange={(e) => setFieldValue(`supervisees[${index}].name`, e.target.value)}
                                />

                                <TextField
                                    {...superviseeFields.department}
                                    value={supervisee.department}
                                    variant="outlined"
                                    fullWidth
                                    onChange={(e) => setFieldValue(`supervisees[${index}].department`, e.target.value)}
                                />

                                <TextField
                                    {...superviseeFields.jobTitle}
                                    value={supervisee.jobTitle}
                                    variant="outlined"
                                    fullWidth
                                    onChange={(e) => setFieldValue(`supervisees[${index}].jobTitle`, e.target.value)}
                                />

                                <IconButton onClick={() => arrayHelpers.remove(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Stack>
                        ))}
                        <Button
                            onClick={() =>
                                arrayHelpers.push({
                                    userId:'',
                                    name: '',
                                    department: '',
                                    jobTitle: '',
                                })
                            }
                        >
                            Add Supervisee
                        </Button>
                    </React.Fragment>
                )}
            />
        </Stack>
    );
};

export default CreateSupervisorForm;
