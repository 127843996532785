import React, { useEffect, useState } from 'react';
import { Button, Stack, Typography } from "@mui/material";
import { Form, Formik } from 'formik';


import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enGB } from 'date-fns/locale';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";


import supervisorFormModel, { createSupervisorModelType,SuperviseeType } from '../forms/models/supervisorModel';
import { Supervisor, SupervisorData, useGetUserByIdQuery, useGetUsersMeQuery, usePostSupervisorsMutation } from '../../redux/vmsApi';
import LoadingCover from '../common/LoadingCover';
import CreateSupervisorForm from './CreateSupervisorForm';



type MyFormValues = createSupervisorModelType;

const createSupervisorModelInitial = supervisorFormModel.initialValues;

type Props = {
  onSuccess?: (supervisor: Supervisor) => void;
};

const CreateSupervisorComponent: React.FC<Props> = ({ onSuccess }) => {
  const [createSupervisor, { isSuccess, isLoading }] = usePostSupervisorsMutation();
  const [showOverlay, setShowOverlay] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  useEffect(() => {
    setShowOverlay(isLoading || isSuccess);
    if (isSuccess) {
      setTimeout(() => {
        setShowOverlay(false);
        setShowSuccessMsg(true);
      }, 3000);
    }
  }, [isLoading, isSuccess]);

  const initialValues: MyFormValues = {
    ...createSupervisorModelInitial,
  };

  const handleSubmit = async (values: MyFormValues) => {
    console.log("++++++++values+++++++++++++",values)
    const superVisorId = values.userId;
    const supervisees: SuperviseeType[] = values.supervisees.map(supervisee => ({
        name: supervisee.name,
        department: supervisee.department,
        jobTitle: supervisee.jobTitle,
        userId: supervisee.userId,  
      }));

    const supervisorData: SupervisorData = {
      userId: superVisorId,
      supervisees: supervisees,
    };
    console.log("++++++++supervisorData++++++++",supervisorData)
    setShowOverlay(true);
    try {
      const result = await createSupervisor({ supervisorData }).unwrap();
      setShowOverlay(false);
      if (result) {
        console.log('success');
      }
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {
      
      setShowOverlay(false);
      console.error("Error submitting form:", error);
    }
  };

  const resetForm = () => {
    window.location.reload()
  }

  return (
    <React.Fragment>
      {showOverlay && <LoadingCover isSuccess={isSuccess}/>}
      {!showSuccessMsg ?
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          // validationSchema={}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
            <Form>
              <Stack spacing={4} width="100%" p={10} pb={5}>
                <CreateSupervisorForm/>
                <Button
                  variant="contained"
                  type="submit"
                >
                  Create
                </Button>
              </Stack>
            </Form>
          </LocalizationProvider>
        </Formik>:
        <Stack>
          <Typography>Thank you, your response has been received</Typography>
          <Button onClick={() => resetForm()} variant={'text'} sx={{maxWidth: '200px'}}>Create Another</Button>
        </Stack>

      }
    </React.Fragment>
  );
};

export default CreateSupervisorComponent;