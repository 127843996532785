import React, { useEffect, useState } from 'react';
import { Button, Stack, Typography } from "@mui/material";
import { Form, Formik } from 'formik';


import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enGB } from 'date-fns/locale';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import createDevelopmentModel, { DevelopmentType } from '../../forms/models/createDevelopmentModel';
import LoadingCover from '../../common/LoadingCover';
import CreateDevelopmentForm from '../forms/CreateDevelopmentForm';
import { Development, DevelopmentData, Project, usePostDevelopmentMutation } from '../../../redux/vmsApi';



type MyFormValues = DevelopmentType;

const createDevelopmentModelInitial = createDevelopmentModel.initialValues;

type Props = {
  onSuccess?: (development: Development) => void;
};

const CreateDevelopmentComponent: React.FC<Props> = ({ onSuccess }) => {
  const [createDevelopment, { isSuccess, isLoading }] = usePostDevelopmentMutation();
  const [showOverlay, setShowOverlay] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  useEffect(() => {
    setShowOverlay(isLoading || isSuccess);
    if (isSuccess) {
      setTimeout(() => {
        setShowOverlay(false);
        setShowSuccessMsg(true);
      }, 3000);
    }
  }, [isLoading, isSuccess]);

  const initialValues: MyFormValues = {
    ...createDevelopmentModelInitial,
  };

  const handleSubmit = async (values: MyFormValues) => {
    const projects: Project[] = values.projects.map(project => ({
      name: project.projectName,
      description: project.projectDescription,
      status: project.projectStatus,
      startDate: project.projectStartDate?.toString(),  
    }));

    const developmentData: DevelopmentData = {
      name: values.name,
      description: values.description,
      projects: projects,
    };

    setShowOverlay(true);
    try {
      const result = await createDevelopment({ developmentData }).unwrap();
      setShowOverlay(false);
      if (result) {
        console.log('success');
      }
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {
      // Handle error state
      setShowOverlay(false);
      console.error("Error submitting form:", error);
    }
  };

  const resetForm = () => {
    window.location.reload()
  }

  return (
    <React.Fragment>
      {showOverlay && <LoadingCover isSuccess={isSuccess}/>}
      {!showSuccessMsg ?
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          // validationSchema={}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
            <Form>
              <Stack spacing={4} width="100%" p={10} pb={5}>
                <CreateDevelopmentForm/>
                <Button
                  variant="contained"
                  type="submit"
                >
                  Create
                </Button>
              </Stack>
            </Form>
          </LocalizationProvider>
        </Formik>:
        <Stack>
          <Typography>Thank you, your response has been received</Typography>
          <Button onClick={() => resetForm()} variant={'text'} sx={{maxWidth: '200px'}}>Create Another</Button>
        </Stack>

      }
    </React.Fragment>
  );
};

export default CreateDevelopmentComponent;