import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Card,
  CardContent,
  CardActions,
  LinearProgress,
  Dialog,
} from '@mui/material';
import { Delete as DeleteIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
import axios from 'axios';
import { usePostGetSignedUrlMutation } from '../../redux/vmsApi';
import {uploadFileToS3} from "../../utils/mediaUtil";

interface DropzoneAreaProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  fieldName: string;
  attachments: { name: string; url: string }[];
}

const DropzoneArea: React.FC<DropzoneAreaProps> = ({ setFieldValue, fieldName, attachments = [] }) => {
  const [localAttachments, setLocalAttachments] = useState(attachments);
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const [openDialog, setOpenDialog] = useState<string | null>(null); // For inline file viewing
  const [getSignedUrl] = usePostGetSignedUrlMutation();

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    try {
      setUploadProgress(0); // Start upload progress

      const newAttachments = await Promise.all(
        acceptedFiles.map(async (file) => {
          const { name, url } = await uploadFileToS3(file, getSignedUrl, setUploadProgress);
          return { name, url };
        })
      );

      const updatedAttachments = [...localAttachments, ...newAttachments];
      setLocalAttachments(updatedAttachments);

      // Update Formik's state
      setFieldValue(fieldName, updatedAttachments);

      setUploadProgress(null); // Reset upload progress after completion
    } catch (error) {
      console.error('Error handling file drop:', error);
      setUploadProgress(null); // Reset on error
    }
  }, [localAttachments, getSignedUrl, setFieldValue, fieldName]);

  const handleRemoveFile = useCallback((attachmentToRemove: { name: string; url: string }) => {
    const updatedAttachments = localAttachments.filter(attachment => attachment.url !== attachmentToRemove.url);
    setLocalAttachments(updatedAttachments);

    // Update Formik's state
    setFieldValue(fieldName, updatedAttachments);
  }, [localAttachments, setFieldValue, fieldName]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'application/pdf': [] },
    onDrop,
  });

  const handleDialogOpen = (url: string) => setOpenDialog(url);
  const handleDialogClose = () => setOpenDialog(null);

  return (
   < Card>
      <CardContent>
        <Box {...getRootProps()} border="2px dashed #ccc" p={2} borderRadius={4} textAlign="center">
          <input {...getInputProps()} />
          <Typography>Drag and drop some files here, or click to select files</Typography>
        </Box>

        {uploadProgress !== null && (
          <Box mt={2}>
            <LinearProgress variant="determinate" value={uploadProgress} />
            <Typography variant="caption">{`Uploading... ${uploadProgress}%`}</Typography>
          </Box>
        )}

        <List>
          {localAttachments.length > 0 ? (
            localAttachments.map((attachment, index) => (
              <ListItem
                key={index}
                secondaryAction={
                  <>
                    <IconButton edge="end" onClick={() => handleDialogOpen(attachment.url)}>
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton edge="end" onClick={() => handleRemoveFile(attachment)}>
                      <DeleteIcon />
                    </IconButton>
                  </>
                }
              >
                <ListItemText primary={attachment.name} />
              </ListItem>
            ))
          ) : (
            <Typography>No attachments available</Typography>
          )}
        </List>
      </CardContent>

      <CardActions>
        
      </CardActions>

      
      <Dialog open={!!openDialog} onClose={handleDialogClose} fullWidth maxWidth="md">
        <Box p={2}>
          {openDialog && (
            <iframe
              src={openDialog}
              title="File Viewer"
              width="100%"
              height="500px"
              style={{ border: 'none' }}
            />
          )}
        </Box>
      </Dialog>
    </Card>
  );
};

export default DropzoneArea;
