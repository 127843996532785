import React, { useState } from 'react';
import { Paper, Tabs, Tab, Typography, Box } from '@mui/material';
import PageWithHeader from "../common/PageWithHeader";
import SiteVisitApplicationForm from './SiteVisitApplicationForm';
import MySiteVisitApplicationList from './MySiteVisitApplicationList';
import SiteVisitApplicationsList from './SiteVisitApplicationsList';
import CreateSupervisorComponent from './CreateSupervisorComponent'; 
import SupervisorsList from './SupervisorsList'; 
import { useGetSupervisorsQuery, useGetUsersMeQuery } from '../../redux/vmsApi';



function TabPanel(props: { children?: React.ReactNode; index: number; value: number }) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}


function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SiteAccessDashboard: React.FC = () => { 
  const [tabValue, setTabValue] = useState(0);  
  const { data: user } = useGetUsersMeQuery();

  
  const { data: supervisors } = useGetSupervisorsQuery({});

  const isSupervisor = supervisors?.some((supervisor) => supervisor.userId === user?.id);


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <PageWithHeader>
      <Paper elevation={2} sx={{ margin: 10, padding: 5, borderRadius: '15px' }}>
        <Typography variant='h1' gutterBottom sx={{ fontWeight: 'bold', color: '#123456' }}>
          Site Access Dashboard
        </Typography>

        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="site visit application tabs"
          textColor="primary"
          indicatorColor="primary"
          variant="fullWidth"
          sx={{ marginBottom: 3 }}
        >
          <Tab label="Apply For Site Visit" {...a11yProps(0)} />
          <Tab label="My Applications" {...a11yProps(1)} />
          <Tab label="Applications To be Approved" {...a11yProps(2)}
             sx={{ visibility: isSupervisor ? 'visible' : 'hidden' }} />
          
          
       
          <Tab label="Supervisors" {...a11yProps(3)} 
           sx={{ visibility: user?.roles?.includes('admin') ? 'visible' : 'hidden' }}
          /> 
         
        </Tabs>

        <TabPanel value={tabValue} index={0}>
          <Paper sx={{ my: 5 }}>
            <SiteVisitApplicationForm />
          </Paper>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Paper sx={{ my: 5 }}>
            <MySiteVisitApplicationList />
          </Paper>
        </TabPanel>

        {isSupervisor && (
        <TabPanel value={tabValue} index={2}>
          <Paper sx={{ my: 5 }}>
            <SiteVisitApplicationsList />
          </Paper>
        </TabPanel>
        )}
        
        {user?.roles?.includes('admin') && (
          <TabPanel value={tabValue} index={3}>
            <Paper sx={{ my: 5 }}>
              <CreateSupervisorComponent />   
              <SupervisorsList />             
            </Paper>
          </TabPanel>
        )}
      </Paper>
    </PageWithHeader>
  );
};


export default SiteAccessDashboard;
