import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  CircularProgress,
  Typography,
  Modal,
  Paper,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { ActionsContext } from './ActionsContext'; // Ensure the correct context is imported

// Declare NDEFReader for TypeScript
declare global {
  interface Window {
    //@ts-ignore
    NDEFReader: typeof NDEFReader;
  }
}

interface Actions {
  scan: string | null;
}

interface ActionsContextType {
  actions: Actions;
  setActions: React.Dispatch<React.SetStateAction<Actions>>;
}

// Dark theme creation
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#f48fb1',
    },
    background: {
      default: '#424242', // Dark grey background
      paper: '#616161', // Slightly lighter grey for paper components
    },
    text: {
      primary: '#ffffff', // White text for primary content
      secondary: '#eeeeee', // Light grey for secondary text
    },
  },
});

const Scanner: React.FC = () => {
  const [serialNumber, setSerialNumber] = useState<string>(''); // For storing NFC serial number
  const [open, setOpen] = useState<boolean>(false); // For controlling the superior modal
  const { actions, setActions } = useContext(ActionsContext) as ActionsContextType;

  const employee = {
    name: 'Devyn Sharpe',
    department: 'IT',
    jobTitle: 'Chief Technology Officer',
    superior: 'Ken Sharpe',
    cardId: 'H658JJ',
    imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZFtDOIYGz_PIvDcQ8A2coDHeZcSMImGQI_g&s',
    superiorContact: {
      email: 'ken.sharpe@example.com',
      phone: '+123456789',
    },
  };

  const scan = useCallback(async () => {
    if ('NDEFReader' in window) {
      try {
        const ndef = new window.NDEFReader();
        await ndef.scan();

        console.log('Scanner started successfully.');
        ndef.onreadingerror = () => {
          console.log('Cannot read data from the NFC tag. Try another one?');
        };

        ndef.onreading = (event: any) => {
          console.log('NDEF message read.');
          onReading(event);
          setActions({ scan: 'scanned' });
        };
      } catch (error) {
        console.log(`Error! Scan failed to start: ${error}.`);
      }
    }
  }, [setActions]);

  const onReading = ({ serialNumber }: { serialNumber: string }) => {
    setSerialNumber(serialNumber);
  };

  useEffect(() => {
    scan();
  }, [scan]);

  // Handler for opening/closing the superior modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Handler to reset scan state
  const handleBack = () => {
    setActions({ scan: null });
    setSerialNumber(''); // Reset serial number
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <>
        {actions.scan !== 'scanned' ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            p={2}
            bgcolor="background.default"
          >
            <img
              src={employee.imageUrl}
              alt="Employee Avatar"
              style={{ width: '150px', height: '150px', borderRadius: '50%', marginBottom: '20px' }}
            />
            <Box textAlign="center">
              <Typography variant="h5" gutterBottom color="text.primary">{employee.name}</Typography>

              {/* Employee Information Boxes */}
              <Box
                border={1}
                borderRadius={2}
                borderColor="grey.600"
                p={2}
                mb={2}
                width="100%"
              >
                <Typography variant="body1" color="text.primary"><strong>Department:</strong> {employee.department}</Typography>
              </Box>
              <Box
                border={1}
                borderRadius={2}
                borderColor="grey.600"
                p={2}
                mb={2}
                width="100%"
              >
                <Typography variant="body1" color="text.primary"><strong>Job Title:</strong> {employee.jobTitle}</Typography>
              </Box>
              <Box
                border={1}
                borderRadius={2}
                borderColor="grey.600"
                p={2}
                mb={2}
                width="100%"
              >
                <Typography variant="body1" color="text.primary"><strong>Card ID:</strong> {employee.cardId}</Typography>
              </Box>
              <Box
                border={1}
                borderRadius={2}
                borderColor="grey.600"
                p={2}
                mb={2}
                width="100%"
              >
                {/* Button to open the superior's contact details */}
                <Typography variant="body1" color="text.primary">
                  <strong>Superior:</strong>{' '}
                  <Button variant="outlined" size="small" onClick={handleOpen} color="primary">
                    {employee.superior}
                  </Button>
                </Typography>
              </Box>

              {/* Mock Access Indicator */}
              <Box
                bgcolor="green"
                borderRadius={2}
                p={2}
                mb={2}
                width="100%"
              >
                <Typography variant="body1" color="text.primary" textAlign="center">
                  Site Access At All Times
                </Typography>
              </Box>

              {/* Add Back and Scan Another Buttons */}
              <Box mt={2}>
                <Button variant="contained" onClick={handleBack} color="secondary" style={{ marginRight: '10px' }}>
                  Back
                </Button>
                <Button variant="contained" onClick={handleBack} color="primary">
                  Scan Another
                </Button>
              </Box>

              {/* Modal for Superior Contact Information */}
              <Modal open={open} onClose={handleClose}>
                <Paper
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: '20px',
                    outline: 'none',
                    backgroundColor: '#616161', // Use the same paper color for consistency
                  }}
                >
                  <Typography variant="h6" gutterBottom color="text.primary">
                    {employee.superior}'s Contact Details
                  </Typography>
                  <Typography variant="body1" color="text.primary">
                    <strong>Email:</strong> {employee.superiorContact.email}
                  </Typography>
                  <Typography variant="body1" color="text.primary">
                    <strong>Phone:</strong> {employee.superiorContact.phone}
                  </Typography>
                  <Box mt={2}>
                    <Button variant="contained" onClick={handleClose}>
                      Close
                    </Button>
                  </Box>
                </Paper>
              </Modal>
            </Box>
          </Box>
        ) : (
          <Box
            position="absolute"
            top="50%"
            left="50%"
            width="250px"
            height="300px"
            bgcolor="background.paper"
            borderRadius="15px"
            boxShadow="0px 0px 10px rgba(0, 0, 0, 0.2)"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{ transform: 'translate(-50%, -50%)' }}
          >
            <Box position="absolute" top="10px" right="10px">
              <IconButton onClick={() => setActions({ ...actions, scan: null })} color="inherit">
                <CloseIcon />
              </IconButton>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <CircularProgress size={60} />
              <Typography variant="body1" mt={2} color="text.primary">Hold your card near the scanner</Typography>
            </Box>
          </Box>
        )}
      </>
    </ThemeProvider>
  );
};

export default Scanner;
