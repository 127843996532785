import React from 'react';
import PageWithHeader from "../common/PageWithHeader";
import { Card, CardContent, Grid, Paper, Typography } from '@mui/material';
import { Link, useRouteMatch } from 'react-router-dom';
import { AppRegistration, AttachMoney, Home, PeopleAlt } from "@mui/icons-material";
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(() => ({
  height: '230px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#1976d2',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  transition: '0.3s',
  borderRadius: '15px',
  '&:hover': {
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
  },
}));

const StyledTypography = styled(Typography)({
  textAlign: 'center',
  color: '#fff',
});

const PropertyRegisterDashboard: React.FC = () => {
  const { url } = useRouteMatch();

  const options = [
    { id: 1, title: 'Log Payment', link: `${url}/add-payment`, icon: <AttachMoney sx={{ fontSize: 60, mb: 1, color: '#fff' }} /> },
    { id: 2, title: 'Clients', link: `${url}/clients`, icon: <PeopleAlt sx={{ fontSize: 60, mb: 1, color: '#fff' }} /> },
    { id: 3, title: 'Properties', link: `${url}/properties`, icon: <Home sx={{ fontSize: 60, mb: 1, color: '#fff' }} /> },
    { id: 4, title: 'View Register', link: `${url}/view`, icon: <AppRegistration sx={{ fontSize: 60, mb: 1, color: '#fff' }} /> },
  ];

  return (
    <PageWithHeader>
      <Paper elevation={2} sx={{ margin: 10, padding: 5, borderRadius: '15px' }}>
        <Typography variant='h1' gutterBottom sx={{ fontWeight: 'bold', color: '#123456' }}>
          Property Register Dashboard
        </Typography>
        <Typography variant='body1' gutterBottom>
          Welcome to the Property Register. This central hub allows you to efficiently manage property-related tasks,
          including logging payments, tracking client interactions, and maintaining property records. Use the
          following tools to streamline your property management workflow and ensure all entries are up-to-date and
          accurate.
        </Typography>

        <Grid container spacing={3} pt={3}>
          {options.map((option) => (
            <Grid item xs={6} sm={3} md={3} key={option.id}>
              <Link to={option.link} style={{ textDecoration: 'none', color: 'inherit', display: 'block' }}>
                <StyledCard>
                  <CardContent style={{ textAlign: 'center' }}>
                    {option.icon}
                    <StyledTypography variant="h6">{option.title}</StyledTypography>
                  </CardContent>
                </StyledCard>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </PageWithHeader>
  );
}

export default PropertyRegisterDashboard;
