import React from 'react';
import PageWithHeader from "../common/PageWithHeader";
import {Box, Button, Paper, Typography} from "@mui/material";
import {push} from "connected-react-router/immutable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SPEARTable from "./SPEARTable";
import {useAppDispatch} from "../../redux/hooks";
import LifeWheelVisualisation from "./LifeWheelVisualisation";
import {useGetLifeWheelByIdQuery} from "../../redux/vmsApi";
import LoadingCover from "../common/LoadingCover";

interface OwnProps {
  match?: any;
}

const AdminLifeWheel: React.FC<OwnProps> = (props) => {
  const {match} = props;
  const dispatch = useAppDispatch();

  const { data: lifeWheelData, isLoading} = useGetLifeWheelByIdQuery({id: match.params.uuid})

  return (
    <PageWithHeader>
      <Box padding={5}>
        <Button color="primary" onClick={() => dispatch(push('/reviews/admin'))}>
          <ArrowBackIcon/>
          <Typography color="primary" align="left">Admin Dashboard</Typography>
        </Button>
        <Paper
          elevation={3}
          sx={{
            paddingY: 15,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {(!isLoading && lifeWheelData) ? (
            <LifeWheelVisualisation data={lifeWheelData.responses[0]} width={600} />
          ) : (
            <LoadingCover />
          )}
        </Paper>
      </Box>
    </PageWithHeader>
  )
}

export default AdminLifeWheel;