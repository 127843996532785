import React from 'react';
import {Paper, Typography} from "@mui/material";


import ProcurementContainer from '../containers/ProcurementContainer';

import CreateStakeholderComponent from '../../common/CreateStakeholderComponent';
import StakeholdersList from '../lists/StakeholderList';

const SuppliersPage: React.FC = () => {
  return (
    <ProcurementContainer>
      <Typography variant='h1' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
       Suppliers
      </Typography>
      <Paper sx={{my: 5}}>
        <CreateStakeholderComponent/>
      </Paper>
      <Paper sx={{my: 5}}>
        <StakeholdersList/>
      </Paper>
    </ProcurementContainer>

  )
}

export default SuppliersPage;