import React from 'react';
import {Button, IconButton, MenuItem, Stack, TextField, Typography} from '@mui/material';
import { Field, FieldArray, useFormikContext, FieldProps } from 'formik';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';

import developmentFormModel from '../../forms/models/createDevelopmentModel';

const {
  formField: {
    name,
    description,
    projects: {
      fields: projectFields
    }
  },
} = developmentFormModel;

interface OwnProps {
  editing?: boolean;
}

const CreateDevelopmentForm: React.FC<OwnProps> = (props) => {
  const {editing=false} = props;
  const { errors, touched, values, setFieldValue } = useFormikContext<any>();

  const handleDatePickerChange = (index: number, value: any) => {
    setFieldValue(`projects[${index}].projectStartDate`, value);
  };

  const statusOptions = [
    { value: "Completed" },
    { value: "Not Started" },
    { value: "In Progress" },
  ];

  return (
    <Stack width="100%" spacing={5}>
      <Typography variant='h4' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
        {editing ? 'Edit' : 'Add'} Development
      </Typography>
      <Stack direction={'row'} spacing={2}>
        <Field
          as={TextField}
          fullWidth
          {...name}
          error={touched[name.name] && Boolean(errors[name.name])}
          helperText={touched[name.name] && errors[name.name] ? errors[name.name] : ''}
        />

        <Field
          as={TextField}
          fullWidth
          {...description}
          error={touched[description.name] && Boolean(errors[description.name])}
          helperText={touched[description.name] && errors[description.name] ? errors[description.name] : ''}
        />
      </Stack>

      <FieldArray
        name="projects"
        render={arrayHelpers => (
          <React.Fragment>
            {values.projects.map((project: any, index: number) => (
              <Stack key={index} direction="row" spacing={2} alignItems="center">
                <TextField
                  {...projectFields.projectName}
                  value={project.projectName}
                  variant="outlined"
                  fullWidth
                  onChange={e => setFieldValue(`projects[${index}].projectName`, e.target.value)}
                />
                <TextField
                  {...projectFields.projectDescription}
                  value={project.projectDescription}
                  variant="outlined"
                  fullWidth
                  onChange={e => setFieldValue(`projects[${index}].projectDescription`, e.target.value)}
                />

                <Field
                  as={TextField}
                  select
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue(`projects[${index}].projectStatus`, e.target.value)}
                  {...projectFields.projectStatus}
                  value={project.projectStatus}
                  error={touched[`projects[${index}].projectStatus`] && Boolean(errors[`projects[${index}].projectStatus`])}
                  helperText={touched[`projects[${index}].projectStatus`] && errors[`projects[${index}].projectStatus`] ? errors[`projects[${index}].projectStatus`] : ''}
                  fullWidth
                >
                  {statusOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.value}
                    </MenuItem>
                  ))}
                </Field>

                <Field
                  name={`projects[${index}].projectStartDate`}
                  render={({ field }: FieldProps) => (
                    <DatePicker
                      {...projectFields.projectStartDate}
                      {...field}
                      inputFormat="dd/MM/yyyy"
                      renderInput={(params) => <TextField {...params} fullWidth />}
                      onChange={(value) => handleDatePickerChange(index, value)}
                    />
                  )}
                />

                {/* Delete Item */}
                <IconButton onClick={() => arrayHelpers.remove(index)}>
                  <DeleteIcon />
                </IconButton>
              </Stack>
            ))}
            <Button
              onClick={() => arrayHelpers.push({
                projectName: '',
                projectDescription: '',
                projectStatus: '',
                projectStartDate: new Date(),
              })}
            >
              Add Project
            </Button>
          </React.Fragment>
        )}
      />
    </Stack>
  );
};

export default CreateDevelopmentForm;
