import React, { useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, Select, MenuItem, Typography, Button, Grid } from '@mui/material';
import { EmployeeReview, UserWithRelations, EmployeeReviewPartial } from "../../redux/vmsApi";
import { usePatchEmployeeReviewByIdMutation } from "../../redux/vmsApi";

interface OwnProps {
  reviewData: EmployeeReview;
  userData: UserWithRelations | null;
  admin?: boolean;
  onSave?: (updatedData: any) => Promise<void>; // Optional, since we'll use the API call directly now
}

const ratingOptions = [
  { value: 'A+', label: 'A+' },
  { value: 'A', label: 'A' },
  { value: 'A-', label: 'A-' },
  { value: 'B+', label: 'B+' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
  { value: 'C-', label: 'C-' },
  { value: 'D', label: 'D' },
  { value: 'E', label: 'E' },
  { value: 'F', label: 'F' },
];

const SPEARTable: React.FC<OwnProps> = (props) => {
  const { reviewData, userData, admin = false } = props;
  const [managerRating, setManagerRatings] = useState<{ [key: string]: string }>({});
  const [isDirty, setIsDirty] = useState(false);

  // Initialize the mutation hook
  const [patchEmployeeReviewById] = usePatchEmployeeReviewByIdMutation();

  const handleManagerRatingChange = (key: string, value: string) => {
    setManagerRatings((prevRatings) => ({
      ...prevRatings,
      [key]: value,
    }));
    setIsDirty(true); // Mark as dirty when a change is made
  };

  const handleSave = async () => {
    const updatedReview: EmployeeReviewPartial = {
      ...reviewData,
      accomplishments: reviewData.accomplishments?.map((item, index) => ({
        ...item,
        // Create a new field for manager's rating
        managerRating: managerRating[`accomplishment-${index}`] || '', // Assuming you want null if not rated
      })),
      quarterlyGoals: reviewData.quarterlyGoals?.map((item, index) => ({
        ...item,
        managerRating: managerRating[`quarterlyGoal-${index}`] || '',
      })),
      monthlyGoals: reviewData.monthlyGoals?.map((item, index) => ({
        ...item,
        managerRating: managerRating[`monthlyGoal-${index}`] || '',
      })),
    };

    // Make the API call to save the updated review
    try {
      await patchEmployeeReviewById({
        id: reviewData.id ?? '', // Assuming `id` is available in `reviewData`
        employeeReviewPartial: updatedReview,
      }).unwrap(); // Use unwrap to handle promise resolution
      setIsDirty(false); // Reset the dirty state after successful save
    } catch (error) {
      console.error("Failed to save employee review:", error);
      // You can add error handling logic here, like showing a message to the user
    }
  };


  return (
    <>
      <TableContainer component={Paper} sx={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h6" gutterBottom>
          Employee Review Details
        </Typography>
        <Table aria-label="employee review details">
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1">Employee Name</Typography>
              </TableCell>
              <TableCell>
                <Typography>{userData?.name || 'N/A'}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1">Department</Typography>
              </TableCell>
              <TableCell>
                <Typography>{userData?.department ?? 'Not specified'}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1">Review Period</Typography>
              </TableCell>
              <TableCell>
                <Typography>{reviewData?.reviewPeriod}</Typography>
              </TableCell>
            </TableRow>

            {/* Render Accomplishments */}
            {reviewData?.accomplishments && reviewData.accomplishments.map((item, index) => (
              <TableRow key={`accomplishment-${index}`}>
                <TableCell>
                  <Typography variant="subtitle1">Accomplishment: {item.title}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Self-assessment: {item.rating}</Typography>
                  {/* Display Manager's adjusted rating if admin is false */}
                  {!admin && item.managerRating && (
                    <Typography variant="body2" color="text.secondary">
                      Manager's Rating: {item.managerRating}
                    </Typography>
                  )}
                </TableCell>
                {admin && (
                  <TableCell>
                    <Select
                      value={managerRating[`accomplishment-${index}`] || item.managerRating || item.rating}
                      onChange={(e) => handleManagerRatingChange(`accomplishment-${index}`, e.target.value)}
                    >
                      {ratingOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                )}
              </TableRow>
            ))}

            {/* Quarterly Goals */}
            {reviewData?.quarterlyGoals && reviewData.quarterlyGoals.map((item, index) => (
              <TableRow key={`quarterlyGoal-${index}`}>
                <TableCell>
                  <Typography variant="subtitle1">Quarterly Goal: {item.title}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Self-assessment: {item.rating}</Typography>
                  {/* Display Manager's adjusted rating if admin is false */}
                  {!admin && item.managerRating && (
                    <Typography variant="body2" color="text.secondary">
                      Manager's Rating: {item.managerRating}
                    </Typography>
                  )}
                </TableCell>
                {admin && (
                  <TableCell>
                    <Select
                      value={managerRating[`quarterlyGoal-${index}`] || item.managerRating || item.rating}
                      onChange={(e) => handleManagerRatingChange(`quarterlyGoal-${index}`, e.target.value)}
                    >
                      {ratingOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                )}
              </TableRow>
            ))}

            {/* Monthly Goals */}
            {reviewData?.monthlyGoals && reviewData.monthlyGoals.map((item, index) => (
              <TableRow key={`monthlyGoal-${index}`}>
                <TableCell>
                  <Typography variant="subtitle1">Monthly Goal: {item.title}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Self-assessment: {item.rating}</Typography>
                  {/* Display Manager's adjusted rating if admin is false */}
                  {!admin && item.managerRating && (
                    <Typography variant="body2" color="text.secondary">
                      Manager's Rating: {item.managerRating}
                    </Typography>
                  )}
                </TableCell>
                {admin && (
                  <TableCell>
                    <Select
                      value={managerRating[`monthlyGoal-${index}`] || item.managerRating || item.rating}
                      onChange={(e) => handleManagerRatingChange(`monthlyGoal-${index}`, e.target.value)}
                    >
                      {ratingOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                )}
              </TableRow>
            ))}

            {/* Other review fields */}
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1">Areas to Improve</Typography>
              </TableCell>
              <TableCell>{reviewData?.areaToImprove}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1">Examples of Excellence</Typography>
              </TableCell>
              <TableCell>{reviewData?.examplesOfExcellence}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1">Main Goal to Achieve Next Month</Typography>
              </TableCell>
              <TableCell>{reviewData?.mainGoalToAchieve}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1">Turns up on time</Typography>
              </TableCell>
              <TableCell>{reviewData?.turnUpOnTime ? 'Yes' : 'No'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {admin && isDirty && (
        <Grid container spacing={2} sx={{ marginTop: '20px' }}>
          <Grid item>
            <Button variant="contained" onClick={handleSave}>
              Save Adjustments
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SPEARTable;
