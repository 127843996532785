import React from 'react';
import {Paper, Typography} from "@mui/material";
import ProcurementContainer from '../containers/ProcurementContainer';
import CreateBillOfMaterialComponent from '../components/CreateBomComponent';
import BillsOfMaterialList from '../lists/BillsOfMaterialList';


const BillOfMaterialsPage: React.FC = () => {
  return (
    <ProcurementContainer>
      <Typography variant='h1' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
        Bills Of Material
      </Typography>
      <Paper sx={{my: 5}}>
        <CreateBillOfMaterialComponent/>
      </Paper>
      <Paper sx={{my: 5}}>
        <BillsOfMaterialList/>
      </Paper>
    </ProcurementContainer>

  )
}

export default BillOfMaterialsPage